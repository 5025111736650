import React from "react";

export function EngageIcon({ className, isActive }) {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="none"
      className={className}
    >
      {isActive ? (
        <path
          d="M10.077 2.614c.338-.76 1.379-.797 1.787-.114l.059.114 1.042 2.345a11 11 0 0 0 4.337 4.931l.322.189 1.85 1.04a1.01 1.01 0 0 1 .109 1.692l-.108.07-1.851 1.04a11 11 0 0 0-4.502 4.782l-.157.338-1.042 2.345c-.338.76-1.379.797-1.787.114l-.059-.114-1.042-2.345a11 11 0 0 0-4.337-4.931l-.322-.189-1.85-1.04a1.01 1.01 0 0 1-.109-1.692l.108-.07 1.851-1.04a11 11 0 0 0 4.502-4.782l.157-.338zm9.144-.493a5.732 5.732 0 0 0 2.414 2.674c.16.09.16.32 0 .41A5.732 5.732 0 0 0 19.22 7.88a.241.241 0 0 1-.44 0 5.732 5.732 0 0 0-2.415-2.674.236.236 0 0 1 0-.41A5.732 5.732 0 0 0 18.78 2.12a.241.241 0 0 1 .44 0Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        ></path>
      ) : (
        <path
          d="M9.107 5.448c.598-1.75 3.016-1.803 3.725-.159l.06.16.807 2.36a4 4 0 0 0 2.276 2.411l.217.081 2.36.806c1.75.598 1.803 3.016.16 3.725l-.16.06-2.36.807a4 4 0 0 0-2.412 2.276l-.081.216-.806 2.361c-.598 1.75-3.016 1.803-3.724.16l-.062-.16-.806-2.36a4 4 0 0 0-2.276-2.412l-.216-.081-2.36-.806c-1.751-.598-1.804-3.016-.16-3.724l.16-.062 2.36-.806A4 4 0 0 0 8.22 8.025l.081-.216zM11 6.094l-.806 2.36a6 6 0 0 1-3.49 3.649l-.25.091-2.36.806 2.36.806a6 6 0 0 1 3.649 3.49l.091.25.806 2.36.806-2.36a6 6 0 0 1 3.49-3.649l.25-.09 2.36-.807-2.36-.806a6 6 0 0 1-3.649-3.49l-.09-.25zM19 2a1 1 0 0 1 .898.56l.048.117.35 1.026 1.027.35a1 1 0 0 1 .118 1.845l-.118.048-1.026.35-.35 1.027a1 1 0 0 1-1.845.117l-.048-.117-.35-1.026-1.027-.35a1 1 0 0 1-.118-1.845l.118-.048 1.026-.35.35-1.027A1 1 0 0 1 19 2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        ></path>
      )}
    </svg>
  );
}
