import { toast } from "sonner";
import { makeObservable, observable, flow, computed, action } from "mobx";

import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import { Add } from "@/components/icons/IconMap";

import BrowseImplementationGroupsSchema from "@/areas/compliance/schemas/BrowseImplementationGroupsSchema";

export default class BrowseImplementationGroupsStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  groups = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      groups: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }

  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];

    list.push({
      id: "settings:implementation",
      label: "New Implementation Group",
      onClick: () => this.parentStore.createImplementationGroupStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createImplementationGroupStore.processing,
      disabled: this.parentStore.createImplementationGroupStore.processing,
    });

    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(group) {
    const index = this.groups.findIndex((u) => u.id === group.id);
    if (index !== -1) {
      this.groups.splice(index, 1, group);
      this.groups = [...this.groups];
    }
  }

  *load(query, options) {
    const parseResult = BrowseImplementationGroupsSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
      this.groups = [];
      this.query = parseResult.data;

      const response = yield api.get(ApiRoutes.forImplementationGroups(), {
        params: query,
      });
      if (response.status == 200) {
        this.groups = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected status code");
      }
    } catch (e) {
      toast.error("Error loading implementation groups", {
        description: e?.response?.data?.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
