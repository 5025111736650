import { z } from "zod";

const CreateDeploymentTargetSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Name is required." })
    .max(400, { message: "Name must not be longer than 400 characters." }),
  description: z.string().optional().nullable(),
  modelId: z.string().uuid({ message: "Target model is required." }),
  scope: z.enum(["root", "type", "label", "level", "custom"], {
    message: "Target scope is required.",
  }),
});

export default CreateDeploymentTargetSchema;
