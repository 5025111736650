import { lazy } from "react";
import { makeObservable, observable, flow, computed, action } from "mobx";
import { WebRoutes } from "@/lib/routes";

import {
  GitCompare,
  Network,
  Sliders,
  Briefcase,
  Table2,
  FolderCheck,
  Grid3X3,
  Newspaper,
  Sticker,
  TrendingUp,
  Home,
  ClipboardCheck,
  FolderHeart,
} from "lucide-react";

import { LoopIcon } from "@/components/icons/LoopIcon";
import { ComplianceIcon } from "@/components/icons/ComplianceIcon";
import { AuditIcon } from "@/components/icons/AuditIcon";
import { AssuranceIcon } from "@/components/icons/AssuranceIcon";
import { SettingIcon } from "@/components/icons/SettingIcon";
import { IncidentIcon } from "@/components/icons/IncidentIcon";
import { RiskIcon } from "@/components/icons/RiskIcon";
import { DemandIcon } from "@/components/icons/DemandIcon";
import { InventoryIcon } from "@/components/icons/InventoryIcon";
import { ReportIcon } from "@/components/icons/ReportIcon";
import { AnalyticsIcon } from "@/components/icons/AnalyticsIcon";
import { GovernanceIcon } from "@/components/icons/GovernanceIcon";

const ControlFeature = lazy(() => import("@/areas/controls/containers/ControlFeature"));
const RisksFeature = lazy(() => import("@/areas/risks/containers/RisksFeature"));

const ReportFeature = lazy(() => import("@/areas/reports/containers/ReportFeature"));
const SettingsFeature = lazy(() => import("@/areas/settings/containers/SettingsFeature"));
const AuditsFeature = lazy(() => import("@/areas/audits/containers/AuditsFeature"));

const FeedRoutes = lazy(() => import("@/areas/activities/containers/routes/FeedRoutes"));
const ControlRoutes = lazy(() => import("@/areas/compliance/containers/routes/ControlRoutes"));
const DomainRoutes = lazy(() => import("@/areas/compliance/containers/routes/DomainRoutes"));
const ActivityRoutes = lazy(() => import("@/areas/compliance/containers/routes/ActivityRoutes"));
const RiskRoutes = lazy(() => import("@/areas/risks/containers/routes/RiskRoutes"));
const TreatmentRoutes = lazy(() => import("@/areas/risks/containers/routes/TreatmentRoutes"));
const ReportRoutes = lazy(() => import("@/areas/analytics/containers/routes/ReportRoutes"));
const InventoryRoutes = lazy(() => import("@/areas/inventory/containers/routes/InventoryRoutes"));

import AppNavigationSchema from "@/areas/apps/schemas/AppNavigationSchema";
import { EngageIcon } from "@/components/icons/EngageIcon";
import { FeedIcon } from "@/components/icons/FeedIcon";
import { TaskIcon } from "@/components/icons/TaskIcon";
import { EventIcon } from "@/components/icons/EventIcon";
import { DocumentIcon } from "@/components/icons/DocumentIcon";
import EngageRoutes from "@/areas/activities/containers/routes/EngageRoutes";
import MyProfileRoutes from "@/areas/activities/containers/routes/MyProfileRoutes";

export default class AppNavigationStore {
  loading = false;
  apps = [
    {
      id: "engage",
      name: "Engage",
      href: WebRoutes.forMyProfile(),
      icon: EngageIcon,
      routes: [
        // { name: "Engage", path: WebRoutes.forEngage(), element: <EngageRoutes />, icon: EngageIcon },
        {
          name: "My Profile",
          path: WebRoutes.forMyProfile(),
          element: <MyProfileRoutes />,
          icon: Sticker,
          isActive: (location) => location.pathname === WebRoutes.forMyProfile(),
        },
        // { name: "Trending", path: WebRoutes.forTrending(), element: <FeedRoutes />, icon: TrendingUp },
      ],
      isActive: (location) =>
        location.pathname === WebRoutes.forMyProfile() ||
        location.pathname.indexOf(WebRoutes.forEngage()) === 0 ||
        location.pathname.indexOf(WebRoutes.forTrending()) === 0,
      location: "start",
    },
    // {
    //   name: "Governance",
    //   href: "/governance",
    //   icon: GovernanceIcon,
    //   element: <ControlFeature />,
    //   isActive: (location) => location.pathname.indexOf("/governance") === 0,
    // },
    {
      id: "compliance",
      name: "Compliance",
      href: WebRoutes.forControls(),
      icon: ComplianceIcon,
      roles: ["admin", "agent"],
      routes: [
        { name: "Controls", path: WebRoutes.forControls(), element: <ControlRoutes />, icon: Sliders },
        { name: "Activities", path: WebRoutes.forActivities(), element: <ActivityRoutes />, icon: ClipboardCheck },
        { name: "Domains", path: WebRoutes.forDomains(), element: <DomainRoutes />, icon: GitCompare },
      ],
      isActive: (location) =>
        location.pathname.indexOf(WebRoutes.forControls()) === 0 ||
        location.pathname.indexOf(WebRoutes.forActivities()) === 0 ||
        location.pathname.indexOf(WebRoutes.forDomains()) === 0,
      location: "start",
    },
    // {
    //   name: "Audits",
    //   href: "/audits",
    //   icon: AuditIcon,
    //   element: <AuditsFeature />,
    //   roles: ["admin", "agent"],
    //   isActive: (location) => location.pathname.indexOf("/audits") === 0,
    // },
    // {
    //   name: "Demand",
    //   href: "/demand",
    //   icon: DemandIcon,
    //   element: <span>Demand</span>,
    // },
    // {
    //   name: "Assurance",
    //   href: "/assurance",
    //   icon: AssuranceIcon,
    //   element: <span>Assurance</span>,
    // },
    // {
    //   name: "Incidents",
    //   href: "/incidents",
    //   icon: IncidentIcon,
    //   element: <span>Incidents</span>,
    // },
    {
      id: "risks",
      name: "Risks",
      href: "/risks",
      icon: RiskIcon,
      routes: [
        { name: "Risks", path: "/risks", element: <RiskRoutes />, icon: Table2 },
        { name: "Treatments", path: "/treatments", element: <TreatmentRoutes />, icon: Briefcase },
      ],
      isActive: (location) =>
        location.pathname.indexOf("/risks") === 0 || location.pathname.indexOf("/treatments") === 0,
      location: "start",
    },
    {
      id: "inventory",
      name: "Inventory",
      href: "/inventory",
      icon: InventoryIcon,
      routes: [
        {
          name: "My Watchlist",
          path: "/inventory",
          element: <InventoryRoutes />,
          icon: FolderHeart,
          isActive: (location) => location.pathname === "/inventory",
        },
        // { name: "My Watchlist", path: "/inventory/watchlist", element: <BrowseWatchlistPage />, icon: FolderHeart },
        // { name: "Models", path: "/inventory", element: <InventoryFeature />, icon: FolderCheck },
      ],
      isActive: (location) => location.pathname.indexOf("/inventory") === 0,
      location: "start",
    },
    // {
    //   id: "reports",
    //   name: "RPT",
    //   href: "/rpt",
    //   icon: ReportIcon,
    //   roles: ["admin", "agent"],
    //   routes: [{ name: "Reports", path: "/rpt", element: <ReportFeature />, icon: ReportIcon }],
    //   isActive: (location) => location.pathname.indexOf("/rpt") === 0,
    // },
    {
      id: "analytics",
      name: "Analytics",
      href: "/reports",
      icon: AnalyticsIcon,
      //roles: ["admin", "agent"],
      routes: [{ name: "Reports", path: "/reports", element: <ReportRoutes />, icon: AnalyticsIcon }],
      isActive: (location) => location.pathname.indexOf("/reports") === 0,
      location: "start",
    },
    {
      id: "settings",
      name: "Settings",
      href: "/settings",
      icon: SettingIcon,
      roles: ["admin"],
      routes: [{ name: "Settings", path: "/settings", element: <SettingsFeature />, icon: SettingIcon }],
      isActive: (location) => location.pathname.indexOf("/settings") === 0,
      location: "end",
    },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      leftNav: computed,
      load: flow,
      apps: observable,
    });

    this.parentStore = parentStore;
  }

  consume(nav) {
    console.log(nav);

    const index = this.apps.findIndex((e) => e.id === nav.id);
    console.log(index);
    if (index !== -1) {
      this.apps.splice(index, 1, nav);
      this.apps = [...this.apps];
    }
    console.log(this.apps);
  }

  get leftNav() {
    const user = this.parentStore.authenticationStore?.user;
    return this.apps.filter((a) => !a.roles || a.roles.indexOf(user?.role) !== -1);
  }

  *load(query, options) {
    const parseResult = AppNavigationSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
