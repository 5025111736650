import React from "react";

export function RiskIcon({ className, isActive }) {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="none"
      className={className}
    >
      {isActive ? (
        <g id="safe_shield_fill" fill="none" fillRule="evenodd">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="currentColor"
            fillRule="nonzero"
            d="m9 9.693 3-1.125 3 1.125v2.335a3 3 0 0 1-1.658 2.683L12 15.382l-1.342-.67A3 3 0 0 1 9 12.027z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="currentColor"
            d="M12.702 2.195a2 2 0 0 0-1.404 0l-7 2.625A2 2 0 0 0 3 6.693v5.363a9 9 0 0 0 4.975 8.05l3.354 1.676a1.5 1.5 0 0 0 1.342 0l3.354-1.677A9 9 0 0 0 21 12.055V6.694a2 2 0 0 0-1.298-1.873zm-.175 4.435a1.5 1.5 0 0 0-1.054 0l-3.5 1.312A1.5 1.5 0 0 0 7 9.347v2.68A5 5 0 0 0 9.764 16.5l1.677.838a1.25 1.25 0 0 0 1.118 0l1.677-.838A5 5 0 0 0 17 12.028V9.347a1.5 1.5 0 0 0-.973-1.405z"
          ></path>
        </g>
      ) : (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
          d="M11.2978,2.19546 C11.6939125,2.04689375 12.1254734,2.02832297 12.530448,2.13974766 L12.7022,2.19546 L19.7022,4.82046 C20.4308533,5.09366533 20.9298818,5.76194569 20.9931804,6.52765634 L21,6.69312 L21,12.0558 C21,15.3645353 19.185628,18.3976292 16.2910032,19.9670871 L16.0249,20.1057 L12.6708,21.7827 C12.2954222,21.9704333 11.8610222,21.9912926 11.4725284,21.8452778 L11.3292,21.7827 L7.97508,20.1057 C5.01569824,18.6260382 3.11426678,15.6467406 3.00497789,12.3558017 L3,12.0558 L3,6.69312 C3,5.91500933 3.45049511,5.21306862 4.14521784,4.8849426 L4.29775,4.82046 L11.2978,2.19546 Z M12,4.06812 L5,6.69312 L5,12.0558 C5,14.61882 6.39981647,16.9692539 8.63528667,18.1941401 L8.8695,18.3168 L12,19.8821 L15.1305,18.3168 C17.42295,17.1706233 18.8991628,14.8674176 18.9950298,12.3201442 L19,12.0558 L19,6.69312 L12,4.06812 Z M11.5085,7.51636 C11.7801286,7.41448 12.0755612,7.39992571 12.353991,7.47269714 L12.4916,7.51636 L15.2916,8.56636 C15.7959692,8.75550769 16.1432166,9.21514533 16.1936548,9.74374893 L16.2,9.87722 L16.2,12.0223 C16.2,13.5408864 15.3810521,14.9350087 14.0690481,15.6773431 L13.8783,15.7789 L12.5367,16.4497 C12.241125,16.5974875 11.8996891,16.6159609 11.5926488,16.5051203 L11.4634,16.4497 L10.1218,15.7789 C8.76353909,15.0998364 7.88284291,13.7438742 7.80558595,12.2383373 L7.80005,12.0223 L7.80005,9.87722 C7.80005,9.33853077 8.1084997,8.8519968 8.5857355,8.61916567 L8.70848,8.56636 L11.5085,7.51636 Z M12,9.46802 L9.80005,10.293 L9.80005,12.0223 C9.80005,12.8000467 10.2102127,13.5155089 10.8708575,13.9103848 L11.0162,13.99 L12,14.482 L12.9839,13.99 C13.6795133,13.64224 14.1360067,12.9554436 14.1937808,12.1879125 L14.2,12.0223 L14.2,10.293 L12,9.46802 Z"
        ></path>
      )}
    </svg>
  );
}
