import React from "react";

export function SettingIcon({ className, isActive }) {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="none"
      className={className}
    >
      {isActive ? (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
          d="M10.586 2.1a2 2 0 0 1 2.7-.116l.128.117L15.314 4H18a2 2 0 0 1 1.994 1.85L20 6v2.686l1.9 1.9a2 2 0 0 1 .116 2.701l-.117.127-1.9 1.9V18a2 2 0 0 1-1.85 1.995L18 20h-2.685l-1.9 1.9a2 2 0 0 1-2.701.116l-.127-.116-1.9-1.9H6a2 2 0 0 1-1.995-1.85L4 18v-2.686l-1.9-1.9a2 2 0 0 1-.116-2.701l.116-.127 1.9-1.9V6a2 2 0 0 1 1.85-1.994L6 4h2.686zM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6"
        ></path>
      ) : (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
          d="M13.4141,2.10056 C12.6331,1.31951 11.3668,1.31951 10.5857,2.10056 L8.68622,4.00005 L5.99991,4.00005 C4.89534,4.00005 3.99991,4.89548 3.99991,6.00005 L3.99991,8.68636 L2.10044,10.5858 C1.31939,11.3669 1.31939,12.6332 2.10043,13.4143 L3.99991,15.3137 L3.99991,18.0001 C3.99991,19.1046 4.89534,20.0001 5.99991,20.0001 L8.68622,20.0001 L10.5857,21.8995 C11.3668,22.6806 12.6331,22.6806 13.4141,21.8995 L15.3136,20.0001 L17.9999,20.0001 C19.1045,20.0001 19.9999,19.1046 19.9999,18.0001 L19.9999,15.3138 L21.8994,13.4143 C22.6805,12.6332 22.6805,11.3669 21.8994,10.5858 L19.9999,8.68632 L19.9999,6.00005 C19.9999,4.89548 19.1045,4.00005 17.9999,4.00005 L15.3136,4.00005 L13.4141,2.10056 Z M11.9999,3.51477 L13.8994,5.41426 C14.2745,5.78934 14.7832,6.00005 15.3136,6.00005 L17.9999,6.00005 L17.9999,8.68632 C17.9999,9.21676 18.2106,9.72547 18.5857,10.1005 L20.4852,12.0001 L18.5857,13.8996 C18.2106,14.2746 17.9999,14.7833 17.9999,15.3138 L17.9999,18.0001 L15.3136,18.0001 C14.7832,18.0001 14.2745,18.2108 13.8994,18.5858 L11.9999,20.4853 L10.1004,18.5858 C9.72536,18.2108 9.21666,18.0001 8.68622,18.0001 L5.99991,18.0001 L5.99991,15.3137 C5.99991,14.7833 5.7892,14.2746 5.41412,13.8995 L3.51465,12.0001 L5.41412,10.1006 C5.7892,9.7255 5.99991,9.2168 5.99991,8.68636 L5.99991,6.00005 L8.68622,6.00005 C9.21665,6.00005 9.72536,5.78934 10.1004,5.41426 L11.9999,3.51477 Z M9.99994,12 C9.99994,10.8954 10.8954,10 11.9999,10 C13.1045,10 13.9999,10.8954 13.9999,12 C13.9999,13.1046 13.1045,14 11.9999,14 C10.8954,14 9.99994,13.1046 9.99994,12 Z M11.9999,8 C9.7908,8 7.99994,9.79086 7.99994,12 C7.99994,14.2091 9.7908,16 11.9999,16 C14.2091,16 15.9999,14.2091 15.9999,12 C15.9999,9.79086 14.2091,8 11.9999,8 Z"
        ></path>
      )}
    </svg>
  );
}
