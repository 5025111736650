import { toast } from "sonner";
import { makeObservable, observable, flow, computed, action } from "mobx";

import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import { Add } from "@/components/icons/IconMap";

import BrowseDeploymentTargetsSchema from "@/areas/compliance/schemas/BrowseDeploymentTargetsSchema";

export default class BrowseDeploymentTargetsStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  deploymentTargets = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      deploymentTargets: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }

  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];

    list.push({
      id: "settings:newDeploymentTargets",
      label: "New Deployment Target",
      onClick: () => this.parentStore.createDeploymentTargetStore.show(),
      icon: Add,
      location: "primary",
      processing: false,
      disabled: false,
    });

    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(deploymentTargets) {
    const index = this.deploymentTargets.findIndex((u) => u.id === deploymentTargets.id);
    if (index !== -1) {
      this.deploymentTargets.splice(index, 1, deploymentTargets);
      this.deploymentTargets = [...this.deploymentTargets];
    }
  }

  *load(query, options) {
    const parseResult = BrowseDeploymentTargetsSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
      this.deploymentTargets = [];
      this.query = parseResult.data;

      const response = yield api.get(ApiRoutes.forDeploymentTargets(), {
        params: query,
      });

      if (response.status == 200) {
        this.deploymentTargets = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected status code");
      }
    } catch (e) {
      toast.error("Error loading deployment targets", {
        description: e?.response?.data?.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
