import { makeObservable, observable, flow, action, computed } from "mobx";
import { toast } from "sonner";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import AddItemToWatchlistStoreSchema from "@/areas/inventory/schemas/AddItemToWatchlistStoreSchema";


export default class AddItemToWatchlistStore {
  visible = false;

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
      canCreate: computed,
    });

    this.parentStore = parentStore;
  }

  get canCreate() {
    return this.parentStore.parentStore.authenticationStore.isAdmin;
  }

  show() {
    this.visible = true;
   
    
  }

  hide() {
    this.visible = false;
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {

    const parseResult = AddItemToWatchlistStoreSchema.safeParse(data);
   
    
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      const response = yield api.post(ApiRoutes.forItemWatchlist(data.id));
      this.visible = false;
      toast.success("Item added to watchlist successfully");
      return { success: true, data: response.data };
    } catch (e) {
      toast.error("Error while adding item to watchlist", {
        description: e.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    }
  }
}
