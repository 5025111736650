import React from "react";

export function AnalyticsIcon({ className, isActive }) {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="none"
      className={className}
    >
      {isActive ? (
        <path
          d="M13 3a2 2 0 0 1 1.995 1.85L15 5v16H9V5a2 2 0 0 1 1.85-1.995L11 3zm7 5a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-3V8zM7 11v10H4a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2z"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        ></path>
      ) : (
        <path
          d="M14 3a2 2 0 0 1 2 2v3h4a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h4V5a2 2 0 0 1 2-2zm0 2h-4v14h4zm6 5h-4v9h4zM8 13H4v6h4z"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        ></path>
      )}
    </svg>
  );
}
