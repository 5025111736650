import { z } from "zod";

const BrowseActivitiesSchema = z.object({
  search: z.string(),
  page: z.number().min(1, { message: "Page is out of bounds" }),
  query: z.any(),
  sort: z.array(z.object({ property: z.string(), asc: z.boolean() })),
});

export default BrowseActivitiesSchema;
