import { makeObservable, observable, flow, computed } from "mobx";

import { Rows3 } from "lucide-react";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import { Edit, Add } from "@/components/icons/IconMap";

import BrowseModelsSchema from "@/areas/inventory/schemas/BrowseModelsSchema";

export default class BrowseModelsStore {
  loading = false;
  pagination = null;
  models = [];
  routes = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      models: observable,
      actions: computed,
      load: flow,
      routes: observable,
    });

    this.parentStore = parentStore;
  }

  get actions() {
    const list = [];

    list.push({
      id: "inventory:addModel",
      label: "Add New Model ",
      onClick: () => this.parentStore.createModelStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createModelStore.processing,
      disabled: this.parentStore.createModelStore.processing || !this.parentStore.createModelStore.canCreate,
    });

    return list;
  }

  consume(model) {
    const index = this.models.findIndex((e) => e.id === model.id);
    if (index !== -1) {
      this.models.splice(index, 1, model);
      this.models = [...this.models];
    }
  }

  *load(query, options) {
    const parseResult = BrowseModelsSchema.safeParse(query);
    if (!parseResult.success) {
      console.log("false parse");
      return parseResult;
    }

    try {
      this.loading = true;
      const response = yield api.post(ApiRoutes.forModelSearch(), query);
      if (response.status == 200) {
        this.models = response.data.items;

        this.routes = response.data.items.map((item) => {
          return {
            name: item.name,
            path: `/inventory/${item.shortCode}`,
            element: "",
            icon: Rows3,
          };
        });

        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      }
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
