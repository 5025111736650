
import BrowseModelsStore from "@/areas/inventory/stores/BrowseModelsStore";
import CreateModelStore from "@/areas/inventory/stores/CreateModelStore";
import CreateItemStore from "@/areas/inventory/stores/CreateItemStore";
import AddItemToWatchlistStore from "@/areas/inventory/stores/AddItemToWatchlistStore";
import BrowseItemWatchlistStore from "@/areas/inventory/stores/BrowseItemWatchlistStore";




export default class InventoryRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.browseModelsStore = new BrowseModelsStore(this);
    this.createModelStore = new CreateModelStore(this);
    this.createItemStore = new CreateItemStore(this);
    this.browseItemWatchlistStore = new BrowseItemWatchlistStore(this);
    this.addItemToWatchlistStore = new AddItemToWatchlistStore(this)
  }
}
