import { z } from "zod";

const CreateImplementationGroupSchema = z
  .object({
    name: z
      .string()
      .min(1, { message: "Name is required." })
      .max(400, { message: "Name must not be longer than 400 characters." }),
    description: z.string().optional().nullable(),
  })
  .strict();

export default CreateImplementationGroupSchema;
