import React from "react";

export function ComplianceIcon({ className, isActive }) {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="none"
      className={className}
    >
      {isActive ? (
        <path
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2m4.243 5.757c-.354-.353-4.95.707-6.364 2.122-1.414 1.414-2.475 6.01-2.122 6.364.354.353 4.95-.707 6.364-2.122 1.415-1.414 2.475-6.01 2.122-6.364M12 11a1 1 0 1 1 0 2 1 1 0 0 1 0-2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        ></path>
      ) : (
        <path
          d="M12,2 C17.5228,2 22,6.47715 22,12 C22,17.5228 17.5228,22 12,22 C6.47715,22 2,17.5228 2,12 C2,6.47715 6.47715,2 12,2 Z M12,4 C7.58172,4 4,7.58172 4,12 C4,16.4183 7.58172,20 12,20 C16.4183,20 20,16.4183 20,12 C20,7.58172 16.4183,4 12,4 Z M16.9498,7.05024 C17.3451231,7.44552923 17.3465876,8.03012976 17.2862468,8.56077817 L17.2492,8.84341 L17.2492,8.84341 C17.0893,9.96276 16.6438,11.6176 16.0074,12.9986 C15.6973,13.6714 15.3066,14.3503 14.8285,14.8284 C14.3504,15.3065 13.6715,15.6972 12.9987,16.0073 C11.6177,16.6437 9.96283,17.0892 8.84348,17.2491 L8.56132853,17.2858644 C8.03077059,17.3454071 7.44254462,17.3419769 7.05031,16.9497 C6.69372538,16.5931615 6.65848988,16.0746112 6.69973321,15.5846727 L6.73169771,15.295668 C6.73790172,15.2485811 6.74438077,15.2021615 6.75089,15.1566 C6.9108,14.0372 7.3563,12.3823 7.99275,11.0014 C8.30284,10.3285 8.6935,9.64968 9.17163,9.17156 C9.64975,8.69343 10.3286,8.30277 11.0014,7.99268 C12.3824,7.35623 14.0373,6.91073 15.1566,6.75082 L15.4392512,6.71376968 C15.9699213,6.6534316 16.5544769,6.65494231 16.9498,7.05024 Z M15.2377,8.76238 C14.3298,8.91654 12.9153,9.31284 11.8386,9.80906 C11.2408,10.0845 10.8148,10.3568 10.5858,10.5858 C10.3569,10.8148 10.0846,11.2408 9.80913,11.8385 C9.31291,12.9152 8.91661,14.3298 8.76246,15.2376 C9.67028,15.0834 11.0848,14.6871 12.1615,14.1909 C12.7593,13.9154 13.1853,13.6432 13.4143,13.4142 C13.6432,13.1852 13.9155,12.7592 14.191,12.1615 C14.6872,11.0848 15.0835,9.67021 15.2377,8.76238 Z M12,11 C12.5523,11 13,11.4477 13,12 C13,12.5523 12.5523,13 12,13 C11.4477,13 11,12.5523 11,12 C11,11.4477 11.4477,11 12,11 Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        ></path>
      )}
    </svg>
  );
}
