import { makeObservable, observable, flow, computed } from "mobx";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import { Add } from "@/components/icons/IconMap";

import BrowseActivitiesSchema from "@/areas/compliance/schemas/BrowseActivitiesSchema";

export default class BrowseActivitiesStore {
  loading = false;
  pagination = null;
  activities = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      activities: observable,
      actions: computed,
      load: flow,
    });

    this.parentStore = parentStore;
  }

  get actions() {
    const list = [];

    list.push({
      id: "compliance:activities:add",
      label: "New Activity",
      onClick: () => this.parentStore.addActivityStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.addActivityStore.processing,
      disabled: this.parentStore.addActivityStore.processing || true,
    });

    // list.push({
    //   id: "activities:export:pdf",
    //   label: "Export to PDF",
    //   onClick: () => {
    //     const link = document.createElement("a");
    //     link.href = AssetRoutes.forExportPdf(window.location.pathname + window.location.search);
    //     link.setAttribute("download", "Activities.pdf");
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    //   },
    //   icon: FileDown,
    //   location: "secondary",
    // });

    return list;
  }

  *load(query, options) {
    const parseResult = BrowseActivitiesSchema.safeParse(query);
    if (!parseResult.success) {
      console.error(parseResult.error);
      return parseResult;
    }

    try {
      this.loading = true;

      const response = yield api.post(ApiRoutes.forActivitySearch(), query);
      this.activities = response.data.items;
      this.pagination = {
        page: response.data.page,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        pageSize: response.data.pageSize,
      };
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
