import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const DefaultAppLayout = lazy(() => import("@/areas/apps/components/layouts/DefaultAppLayout"));
const EngageLandingPage = lazy(() => import("@/areas/activities/containers/pages/EngageLandingPage"));

const EngageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DefaultAppLayout />}>
        <Route index element={<EngageLandingPage />} />
      </Route>
    </Routes>
  );
};

export default EngageRoutes;
