import { z } from "zod";
import UserRefByIdSchema from "@/areas/directory/schemas/UserRefByIdSchema";

const AddActivityPeriodSchema = z
  .object(
    {
      description: z.string().min(1, { message: "Schedule summary is required." }),
      expression: z.union([z.string().min(1, { message: "Schedule is required." }), z.null()]),
    },
    { invalid_type_error: "Due after is required", required_error: "Due after is required" }
  )
  .strict();

const AddActivityScheduleSchema = z
  .object(
    {
      description: z.string().min(1, { message: "Schedule summary is required." }),
      expression: z.union([z.string().min(1, { message: "Schedule is required." }), z.null()]),
      data: z.union([z.string().min(1, { message: "Schedule is required." }), z.null()]),
      startAt: z.string({ invalid_type_error: "Start date is required" }).datetime({ offset: true }),
      endAt: z.union([z.string().datetime({ offset: true }).optional(), z.null()]),
    },
    { invalid_type_error: "Repeat schedule is required", required_error: "Schedule is required" }
  )
  .strict();

const AddActivitySchema = z
  .object({
    controlId: z.string().uuid({ message: "Control identifier is required." }),
    title: z
      .string()
      .min(1, { message: "Title is required." })
      .max(400, { message: "Title must not be longer than 400 characters." }),
    description: z.string().min(1, { message: "Guidance is required." }),
    assignedTo: z.union([UserRefByIdSchema, z.null()]),
    groupId: z.string().uuid({ message: "Implementation group is required." }),
    targetId: z.string().uuid({ message: "Deployment target is required." }),
    schedule: AddActivityScheduleSchema,
    dueAfter: AddActivityPeriodSchema,
  })
  .strict();

export default AddActivitySchema;
